/* ./src/index.css */
@tailwind base;
@import "@material-tailwind/react/tailwind.css";
@import "rc-pagination/assets/index.css";
@tailwind components;
@tailwind utilities;

@layer utilities {
    @responsive {
        .remove-button-bg {
            -webkit-tap-highlight-color: transparent;
        }
        .min-w-150{
            min-width:150px;
        }
        .min-w-200{
            min-width:200px;
        }
        .min-w-250{
            min-width:250px;
        }
        .min-w-300{
            min-width:300px;
        }
        .min-w-400{
            min-width:400px;
        }
        .min-w-500{
            min-width:500px;
        }
        .min-w-600{
            min-width:600px;
        }
        .min-w-700{
            min-width:700px;
        }
        .min-w-800{
            min-width:800px;
        }
        .max-w-100{
            max-width: 100px;
        }
        .max-w-200{
            max-width: 200px;
        }
        .max-w-250{
            max-width: 250px;
        }
        .max-w-300{
            max-width: 300px;
        }
        .max-h-87{
            max-height:87%
        }
        .max-h-90{
            max-height:90%
        }
        .max-h-95{
            max-height:95%
        }
        .min-h-500{
            min-height:500px
        }
        .min-h-600{
            min-height:600px
        }
    }
  }


  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #edf2f7;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #cbd5e0;
    border-radius: 100vh;
    border: 3px solid #edf2f7;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a0aec0;
  }